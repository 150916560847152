/*!
 * navigation.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */


var Navigation = function(parentView) {

    var that = this;

    this.init = function(){

      //Component Properties
      this.parentView = parentView;
      this.menuContainers = $(".menu-container", this.parentView);
      this.primaryLinks = $(".primary-link", this.parentView);
      this.subMenus = $(".sub-menu", this.parentView);


      this.showSubMenu = function(subMenu) {
          subMenu.finish();
          subMenu.css({"opacity":"1"});
          subMenu.stop().animate({
              "max-height": 800,
          }, 500);;
      }

      this.hideSubMenus = function() {
          $(".active-link", this.parentView).removeClass("active-link");
          this.subMenus.stop().animate({
              "max-height": 0,
          }, 200);
      }

      //Add actions to each Container main title
      $.each(this.menuContainers, function(index, menu) {
          var primaryLink = $(".primary-link", menu);
          var subMenu = $(".sub-menu", menu);

          //Add Click action to primary link
          $(menu).on("mouseenter", function(e) {

            if ( $(primaryLink).hasClass("primary-page-active") ){
              return;
            }

              $(primaryLink).addClass("active-link");
              that.showSubMenu(subMenu);
          });

          $(menu).on("mouseleave", function(e) {
              $(primaryLink).removeClass("active-link");
              that.hideSubMenus(subMenu);
          });
      });

      this.positionSubMenus = function() {
        $.each(that.subMenus, function(index, subMenu){
          //Firts give an very big left margin to allow containers to generate their correct width
          $(subMenu).css({"margin-left" : "-5000px" })
          //Calculate the offset ( - 10 to account for padding )
          var offset = $(subMenu).width() - $(subMenu).parent(".menu-container").width() - 10;
          //Apply the new margin
        $(subMenu).css({
          "margin-left" : "-"+offset+"px"
        });

        });
    }

    setTimeout(function(){
      that.positionSubMenus();
    },200);

    }

    this.init();
};
